<template>
  <lazy-component class="about">
    <div class="aboutTopPanel">
      <img src="../assets/images/aboutBackground1.png" alt="" width="100%">
      <div class="aboutTopPanelSub">
        <div class="aboutTitleLarge">{{aboutMainTitle == '' ? 'Collection BEST LUXURY OFFERS' : aboutMainTitle}}</div>
      </div>
    </div>
    
    <div class="aboutItemMainPanel">
      <a-list :grid="{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }" :data-source="aboutPageInfoListTwo">
        <a-list-item slot="renderItem" slot-scope="item">
          <div class="aboutItemPanel">
            <div class="aboutItemTitle">
              {{item.mainname}}
            </div>
            <div class="aboutItemSubTitle">
              {{item.subname}}
            </div>
            <div class="aboutItemDetail">
              {{item.description}}
            </div>
          </div>
        </a-list-item>
      </a-list>
    </div>
          
    <div class="aboutBottomPanel">
      <div class="aboutBottomTitle">OUR STORY</div>
      <div class="aboutBottomText">
        {{aboutFooterText1 == '' ? `VUE Collection was co-founded by Sue Kang, a Collection enthusiast who has visited over 45+
            resorts in the Collection, looking for unique and quality resorts that offer exceptional service
            and experience.` : aboutFooterText1}}
          <br/><br/>           
      </div>
    </div>
  </lazy-component>
</template>
<script>
import { api } from '../helpers/Helpers';
const aboutPageInfoList = [
  {
    mainname: 'EXCLUSIVE OFFERS',
    subname: 'Membership benefit',
    description: 'Receive exclusive offers, upgrades and resort credits booking through VUE.'
  },
  {
    mainname: 'EXPERT KNOWLEDGE',
    subname: 'We are your expert advisors',
    description: 'We have been to all of our resorts and much more to choose the best of the best resorts and provide the resort that is perfect for you.'
  },
  {
    mainname: 'DIRECT PARTNERSHIP',
    subname: 'We work directly with resorts',
    description: 'No 3rd party. We have direct contracts with resorts that we partner with to ensure best rates and availability'
  },
  {
    mainname: 'EXCEPTIONAL SERVICE',
    subname: 'A concierge behind every booking',
    description: 'We’re not just a booking site. your booking is always looked after by one of our concierge to ensure your trip is amazing from start to finish.'
  },
  // {
  //   mainname: 'BOOKING FLEXIBILITY',
  //   subname: 'Flexible cancelation policy',
  //   description: 'We understand the uncertainty in today’s travel. So we want to make it work for you. No deposit is necessary at time of booking (unless its within penalty period). any covid related issues, we ensure your travel is Protected.'
  // },
];

export default {
  
  metaInfo: {
    // Children can override the title.
    title: 'VUE Collection About',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site

    titleTemplate: '%s',
    
    //titleTemplate: '%s ← My maldives',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'We live and breathe the Collection. Every VUE-approved resort has been personally inspected and vetted by expert Travel advisors.'},

      // {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      // {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      // {name: 'description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},
      
      // // OpenGraph data (Most widely used)
      // {property: 'og:title', content: 'VUE Collection About'},
      // {property: 'og:site_name', content: 'VUE Collection About'},
      // // The list of types is available here: http://ogp.me/#types
      // {property: 'og:type', content: 'website'},
      // // Should the the same as your canonical link, see below.
      // {property: 'og:url', content: 'https://vuemaldives.com/about'},
      // {property: 'og:image', content: 'https://vuemaldives.com/images/logo1.png'},
      // // Often the same as your meta description, but not always.
      // {property: 'og:description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},

      // // Twitter card
      // {name: 'twitter:card', content: 'summary'},
      // {name: 'twitter:site', content: 'https://vuemaldives.com/about'},
      // {name: 'twitter:title', content: 'VUE Collection About'},
      // {name: 'twitter:description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},
      // // Your twitter handle, if you have one.
      // {name: 'twitter:creator', content: '@alligatorio'},
      // {name: 'twitter:image:src', content: 'https://vuemaldives.com/images/logo1.png'},

      // // Google / Schema.org markup:
      // {itemprop: 'name', content: 'VUE Collection About'},
      // {itemprop: 'description', content: 'A curated collection of Collection resorts. Visited, reviewed and approved by Travel Experts.'},
      // {itemprop: 'image', content: 'https://vuemaldives.com/images/logo1.png'}
    ],
 
    link: [
      {rel: 'canonical', href: 'https://vuecollection.com/about'}
    ]
  },
  data() {
    return {
      
      aboutSubTitle:'',
      aboutMainTitle:'',
      aboutFooterText1:'',
      aboutFooterText2:'',
      aboutPageInfoList,
      aboutPageInfoListTwo:[],
    };
  },
  
  created () {
    
    this.getAboutPageInfos();
  },
  mounted(){

  },
  methods:{
    getAboutPageInfos:async function(){
        const res = await api.getAboutPageInfo();
        if (res.length > 0){
            this.aboutSubTitle = res[0].aboutSubTitle;
            this.aboutMainTitle = res[0].aboutMainTitle;
            this.aboutFooterText1 = res[0].aboutFooterText1;
            this.aboutFooterText2 = res[0].aboutFooterText2;
            this.aboutPageInfoList = res[0].datalist;
            this.aboutPageInfoListTwo = res[0].datalist;
        }
        if (this.aboutPageInfoListTwo.length % 2 == 1){
          const emptyCell = {
            mainname: '',
            subname: '',
            description: ''
          }
          this.aboutPageInfoListTwo.splice(this.aboutPageInfoListTwo.length - 1, 0, emptyCell);
        }
    },
  },
};
</script>
<style></style>
